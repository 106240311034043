<template>
    <CookieWarning v-if="currentLanguage === 'pl'" :section_title="section_title_pl" :cookie_policy="cookie_policy_pl"/> 
    <CookieWarning v-else :section_title="section_title_en" :cookie_policy="cookie_policy_en"/>

</template>

<script>
import axios from 'axios';
import CookieWarning from '@/components/CookieWarning.vue';

export default {
    components: {
        CookieWarning
    },
    data() {
        return {
            cookieData: {
                items_pl: [{ section_title: '', cookie_policy: '' }],
                items_en: [{ section_title: '', cookie_policy: '' }],
            },
            section_title_pl: '',
            section_title_en: '',
            cookie_policy_pl: '',
            cookie_policy_en: '',

        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';

        axios.get('https://app-energywebsite-pl-backend-dev.azurewebsites.net/api/cookie-policy-content', {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.cookieData = response.data || { items_pl: [], items_en: [] };

                if (this.cookieData.items_pl[0].active === true) {
                    this.section_title_pl = this.cookieData.items_pl[0].section_title;
                    this.cookie_policy_pl = this.cookieData.items_pl[0].cookie_policy;
                }
                if (this.cookieData.items_en[0].active === true) {
                    this.section_title_en = this.cookieData.items_en[0].section_title;
                    this.cookie_policy_en = this.cookieData.items_en[0].cookie_policy;
                }
            })
            .catch(error => {
                console.error('Error fetching cookie warning data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style></style>