<template>
    <section class="page-section section-files-list">
        <div class="container" style="height: auto;">
            <div class="cards-wrapper cards-col-1" style="height: auto;">
                <div class="card-element" style="height: auto;">
                    <div class="card-wrapper" style="height: auto;">
                        <div class="card-content" style="height: auto;">
                            <h2 v-if="currentLanguage === 'pl'"> {{ titlePl }}</h2>
                            <h2 v-if="currentLanguage === 'en'"> {{ titleEn }}</h2>

                            <h5 v-if="currentLanguage === 'pl'" class="card-intro">{{ contentPl }}</h5>
                            <h5 v-if="currentLanguage === 'en'" class="card-intro">{{ contentEn }}</h5>

                            <div v-for="document in documentsData" :key="document.id" class="files-wrapper tab-content"
                                @mouseenter="showButton = true" @mouseleave="showButton = false">
                                <p v-if="currentLanguage === 'pl'">
                                    <img :src="pdfLogo" alt="pdgLogo" />
                                    {{ document.document_name_translated }}
                                    <button v-if="showButton" @click="downloadDocument(document)" class="circle-button">
                                        <img :src="downloadButton" alt="downloadButton" />
                                    </button>
                                </p>
                                <p v-else>
                                    <img :src="pdfLogo" alt="pdgLogo" />
                                    {{ document.document_name }}
                                    <button v-if="showButton" @click="downloadDocument(document)" class="circle-button">
                                        <img :src="downloadButton" alt="downloadButton" />
                                    </button>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import axios from 'axios';

export default {
    data() {
        return {
            downloadButton: require('@/assets/icons/icon_download.png'),
            pdfLogo: require('@/assets/icons/icon_small-pdf.png'),
            titleEn: 'Documents',
            titlePl: 'Dokumenty',
            contentEn: 'Below you can find our Trading License and the mandatory form for SME clients:',
            contentPl: 'Poniżej znajdziesz naszą Licencję handlową i obowiązkowy formularz dla klientów z sektora MŚP:',

            showButton: false,
            documentsData: {},
            documents: [
                // { name: 'Document 1', path: require('@/assets/404.jpg') },
                // { name: 'Document 2', path: 'C:/Users/mibir9/Downloads/Metadata definitions v4.1  (5).pdf' },
                // Consider dynamic path: { name: 'Document 2', path: process.env.DOCUMENT_PATH }
            ],
        };
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    methods: {
        downloadDocument(document) {
            window.location.href = "https://app-energywebsite-pl-backend-dev.azurewebsites.net" + document.document; // Replace with your actual download logic
        },

    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';

        axios.get('https://app-energywebsite-pl-backend-dev.azurewebsites.net/api/documents', {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.documentsData = response.data || { items_pl: [], items_en: [] };
                console.log("documetns:")
                console.log(this.documentsData)



            })
            .catch(error => {
                console.error('Error fetching Documents data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
};
</script>
  
<style >
@import '@/css/main.css';

/* Add component-specific styles if needed */
.files-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin: 0;
    border: none;
    position: relative;
    overflow: hidden;
    /* Add this line to limit expansion */
    transition: height 0.3s ease;
    /* Add a transition for a smooth effect */
}

.files-wrapper:hover {
    background-color: lightblue;
    /* height: 70%; */
    margin-bottom: 0;
    border: none;
    position: relative;
    /* overflow: hidden; */
    /* Add this line to maintain the original height on hover */
}

.circle-button {
    display: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #0058A3;
    background-color: transparent;
    position: absolute;
    top: 25%;
    right: 0;
}

.files-wrapper:hover .circle-button {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #0058A3;
    background-color: transparent;
    position: absolute;
    top: 25%;
    right: 0;
}
</style>
 
 