<template>
    <div class="tab-content form-tabs" :style="{ padding: '4rem', height: 'auto' }">
        <!-- Tab navigation -->
        <ul class="nav nav-tabs" role="tablist" :style="{ height: 'auto' }">
            <li v-for="(tab, index) in tabs" :key="index" class="nav-item" :style="{ height: 'auto' }">
                <a :class="{ 'nav-link': true, active: currentTab === index }" :data-toggle="'tab'" :href="'#' + tab.id"
                    role="tab" :aria-selected="currentTab === index" @click="setCurrentTab(index)">
                    {{ tab.title }}
                </a>
            </li>
        </ul>

        <!-- Tab content -->
        <div class="tab-pane" :class="{ active: currentTab === index }" v-for="(tab, index) in tabs" :key="index"
            :id="tab.id" :style="{ height: 'auto' }">
            <div class="form-item" v-for="(field, fieldIndex) in tab.fields" :key="fieldIndex" :style="{ height: 'auto' }">
                <label>
                    <div v-html="field.label" />
                </label>
                <div v-if="field.type === 'file'" :style="{ marginLeft: '0', left: '0', textAlign: 'justify' }">
                    Are you an SME customer? Please fill in this form
                    <a href="https://app-energywebsite-pl-backend-dev.azurewebsites.net/media/documents/Wzor_oswiadczenia_skladanego_przed_odbiorcow_uprawnionych.pdf"
                        target="_blank"> I&C Clients
                    </a>
                    and upload it here.
                </div>
                <br />
                <input
                    v-if="field.type === 'text' || field.type === 'tel' || field.type === 'email' || field.type === 'number' || field.type === 'file'"
                    :type="field.type" v-model="field.value" />
                <br />
                <label v-for="(option, optionIndex) in field.options" :key="optionIndex" class="form-item form-checkbox "
                    style="display: inline-block;
                    vertical-align: top;
                    font-size: 14px;
                    line-height: 16px;
                    color: #808080;
                    position: static;
                    cursor: pointer;
                    margin-bottom: 0;">
                    <input type="checkbox" :value="option" v-model="field.value" />
                    {{ option }}
                </label>

                <label v-if="field.type === 'checkbox2'" class="form-item form-checkbox">
                 
                    <input type="checkbox" id="gdpr" v-model="gdpr" required>
                    I agree to <a href="/gdpr" target="_blank">GDPR</a> terms
                </label>

                <div v-if="field.type === 'form-actions'" class="form-actions" :style="{ gap: '10px' }">
                    <span class="button-reverse next-tab" v-if="currentTab > 0" @click="goToPreviousTab"
                        :style="{ padding: '1.4rem' }">Previous</span>
                    <span class="button next-tab" @click="goToNextTab" :style="{ padding: '1.4rem' }">{{ currentTab <
                        tabs.length - 1 ? 'Next' : 'Submit' }}</span>

                            <small :style="{ padding: '1.4rem' }">Step {{ currentTab + 1 }} of {{ tabs.length }}</small>
                </div>
            </div>
        </div>
    </div>
</template>
  

<script>
export default {
    data() {
        return {
            currentTab: 0,
            tabs: [
                {
                    id: 'informatii-societate',
                    title: 'Company Information',
                    fields: [
                        { label: 'Company name', type: 'text', value: '' },
                        { label: 'Caen code/Name of activity', type: 'text', value: '' },
                        { label: 'Fiscal code', type: 'text', value: '' },
                        { label: '', type: 'file', value: '' },
                        { label: '', type: 'form-actions' },
                    ],
                },
                {
                    id: 'persoana-de-contact',
                    title: 'Contact person',
                    fields: [
                        { label: 'Contact name', type: 'text', value: '' },
                        { label: 'Phone number', type: 'text', value: '' },
                        { label: 'E-mail', type: 'text', value: '' },
                        { label: '', type: 'form-actions' },

                    ],
                },
                {
                    id: 'date-consum',
                    title: 'Consumption data',
                    fields: [
                        { label: 'Number of consumption places', type: 'text', value: '' },
                        { label: 'Main point of consumption county', type: 'text', value: '' },
                        { label: 'City of main point of consumption', type: 'text', value: '' },
                        { label: 'Total monthly consumption', type: 'text', value: '' },
                        {
                            label: 'Method of invoice transmission',
                            type: 'checkbox',
                            value: [],
                            options: ['E-mail', 'Mail'],
                        },

                        { label: 'I agree to GDPR terms', type: 'checkbox2', value: '' },
                        { label: '', type: 'form-actions' },
                    ],
                }
                // Add similar structures for other tabs
            ],
        };
    },
    methods: {
        setCurrentTab(index) {
            this.currentTab = index;
        },
        goToNextTab() {
            if (this.currentTab < this.tabs.length - 1) {
                this.currentTab++;
            } else {
                // Handle form submission logic
                console.log('Form submitted!');
            }
        },
        goToPreviousTab() {
            if (this.currentTab > 0) {
                this.currentTab--;
            }
        },
    },
};
</script>
 

  
<style>
@import '@/css/main.css';

.form-columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-column {
    width: 48%;
    /* Adjust the width as needed */
    margin-bottom: 15px;
}

.gdpr-submit-container {
    width: 100%;
    text-align: center;
    /* Center content horizontally */
    margin-top: 15px;
}

.gdpr-checkbox {
    margin-top: 10px;
}

.submit-button {
    text-align: center;
}

.column-2-final {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
}

.column-2-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
}

@media all and (max-width: 991px) {
    .column-2-inputs {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        /* Center the columns horizontally */
        align-items: center;
    }
}

input textarea {
    max-width: 100%;
}
</style>