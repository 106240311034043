<template>
    <NavbarMain />

    <PresentationSection v-if="currentLanguage === 'pl'" :photo="first_section_image_pl" :title="first_section_title_pl"
        :content="first_section_content_pl" />
    <PresentationSection v-else :photo="first_section_image_en" :title="first_section_title_en"
        :content="first_section_content_en" />

    <CardSection v-if="currentLanguage === 'pl'" :content-title="third_section_title_pl"
        :content-sentance="third_section_content_pl" :style="{    fontSize: '14px'}"/>
    <CardSection v-else :content-title="third_section_title_en" :content-sentance="third_section_content_en" :style="{    fontSize: '14px'}"/>

    <FooterPage />
</template>

<script>
import axios from 'axios';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';
import CardSection from '@/components/CardSection.vue'

export default {
    components: {
        NavbarMain,
        PresentationSection,
        FooterPage,
        CardSection
    },
    data() {
        return {
            clientsAndOffersData: {
                items_pl: [{ first_section_title: '', first_section_content: '', first_section_image: '', second_section_title: '', second_section_content: '', second_section_image: '', second_section_button_title: '', second_section_button_content: '', second_section_button_link: '', third_section_title: '', third_section_content: '', fourth_section_title: '', fourth_section_content: '', fifth_section_title: '', fifth_section_content: '' }],
                items_en: [{ first_section_title: '', first_section_content: '', first_section_image: '', second_section_title: '', second_section_content: '', second_section_image: '', second_section_button_title: '', second_section_button_content: '', second_section_button_link: '', third_section_title: '', third_section_content: '', fourth_section_title: '', fourth_section_content: '', fifth_section_title: '', fifth_section_content: '' }]
            },

            first_section_title_pl: '',
            first_section_content_pl: '',
            first_section_image_pl: '',
            second_section_title_pl: '',
            second_section_content_pl: '',
            second_section_image_pl: '',
            second_section_button_title_pl: '',
            second_section_button_content_pl: '',
            second_section_button_link_pl: '',
            third_section_title_pl: '',
            third_section_content_pl: '',
            fourth_section_title_pl: '',
            fourth_section_content_pl: '',
            fifth_section_title_pl: '',
            fifth_section_content_pl: '',

            first_section_title_en: '',
            first_section_content_en: '',
            first_section_image_en: '',
            second_section_title_en: '',
            second_section_content_en: '',
            second_section_image_en: '',
            second_section_button_title_en: '',
            second_section_button_content_en: '',
            second_section_button_link_en: '',
            third_section_title_en: '',
            third_section_content_en: '',
            fourth_section_title_en: '',
            fourth_section_content_en: '',
            fifth_section_title_en: '',
            fifth_section_content_en: '',
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';

        axios.get('https://app-energywebsite-pl-backend-dev.azurewebsites.net/api/clients-and-offers-content', {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.clientsAndOffersData = response.data || { items_pl: [], items_en: [] };

                if (this.clientsAndOffersData.items_pl[0].active === true) {
                    this.first_section_title_pl = this.clientsAndOffersData.items_pl[0].first_section_title;
                    this.first_section_content_pl = this.clientsAndOffersData.items_pl[0].first_section_content;
                    this.first_section_image_pl = "https://app-energywebsite-pl-backend-dev.azurewebsites.net" + this.clientsAndOffersData.items_pl[0].first_section_image;
                    this.second_section_title_pl = this.clientsAndOffersData.items_pl[0].second_section_title;
                    this.second_section_content_pl = this.clientsAndOffersData.items_pl[0].second_section_content;
                    this.second_section_image_pl = "https://app-energywebsite-pl-backend-dev.azurewebsites.net" + this.clientsAndOffersData.items_pl[0].second_section_image;
                    this.second_section_button_title_pl = this.clientsAndOffersData.items_pl[0].second_section_button_title;
                    this.second_section_button_content_pl = this.clientsAndOffersData.items_pl[0].second_section_button_content;
                    this.second_section_button_link_pl = this.clientsAndOffersData.items_pl[0].second_section_button_link;
                    this.third_section_title_pl = this.clientsAndOffersData.items_pl[0].third_section_title;
                    this.third_section_content_pl = this.clientsAndOffersData.items_pl[0].third_section_content;
                    this.fourth_section_title_pl = this.clientsAndOffersData.items_pl[0].fourth_section_title;
                    this.fourth_section_content_pl = this.clientsAndOffersData.items_pl[0].fourth_section_content;
                    this.fifth_section_title_pl = this.clientsAndOffersData.items_pl[0].fifth_section_title;
                    this.fifth_section_content_pl = this.clientsAndOffersData.items_pl[0].fifth_section_content;
                }
                if (this.clientsAndOffersData.items_en[0].active === true) {
                    this.first_section_title_en = this.clientsAndOffersData.items_en[0].first_section_title;
                    this.first_section_content_en = this.clientsAndOffersData.items_en[0].first_section_content;
                    this.first_section_image_en = "https://app-energywebsite-pl-backend-dev.azurewebsites.net" + this.clientsAndOffersData.items_en[0].first_section_image;
                    this.second_section_title_en = this.clientsAndOffersData.items_en[0].second_section_title;
                    this.second_section_content_en = this.clientsAndOffersData.items_en[0].second_section_content;
                    this.second_section_image_en = "https://app-energywebsite-pl-backend-dev.azurewebsites.net" + this.clientsAndOffersData.items_en[0].second_section_image;
                    this.second_section_button_title_en = this.clientsAndOffersData.items_en[0].second_section_button_title;
                    this.second_section_button_content_en = this.clientsAndOffersData.items_en[0].second_section_button_content;
                    this.second_section_button_link_en = this.clientsAndOffersData.items_en[0].second_section_button_link;
                    this.third_section_title_en = this.clientsAndOffersData.items_en[0].third_section_title;
                    this.third_section_content_en = this.clientsAndOffersData.items_en[0].third_section_content;
                    this.fourth_section_title_en = this.clientsAndOffersData.items_en[0].fourth_section_title;
                    this.fourth_section_content_en = this.clientsAndOffersData.items_en[0].fourth_section_content;
                    this.fifth_section_title_en = this.clientsAndOffersData.items_en[0].fifth_section_title;
                    this.fifth_section_content_en = this.clientsAndOffersData.items_en[0].fifth_section_content;
                }
            })
            .catch(error => {
                console.error('Error fetching Clients and offers data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}

</script>

<style>
a h5{
    font-size: 14px;
}

.columns-4-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
    gap: 10px;
    padding: auto;
}

@media all and (max-width: 850px) {
    .columns-4-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}

.columns-6-section {
    margin-left: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: stretch;
    /* Make all columns the same height */
    gap: 10px;
    padding: auto;
}

@media all and (max-width: 850px) {
    .columns-6-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}

.CardSection {
    flex: 1;
    /* Distribute available space equally among the cards */
}
</style>